import { ModelApiKey } from '../types/models'
import { LocaleCode } from '../types/locales'

export const paths = {
  [LocaleCode.EN]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Bonus]: { path: 'bonus' },
    [ModelApiKey.Project]: { path: 'projects' }
  },
  [LocaleCode.SL]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Bonus]: { path: 'bonus' },
    [ModelApiKey.Project]: { path: 'projekti' }
  }
}
